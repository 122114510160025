"use client"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { cn } from "@/lib/utils"
import { CheckCircle2 } from "lucide-react"
import { supabaseUserClientComponentClient } from '@/supabase-clients/user/supabaseUserClientComponentClient'
import { useQuery } from '@tanstack/react-query'
import Link from "next/link"
import { useState } from "react"

const CheckItem = ({ text }: { text: string }) => (
    <div className="flex gap-2">
      <CheckCircle2 size={18} className="my-auto text-green-400" />
      <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">{text}</p>
    </div>
  )  

type PricingSwitchProps = {
    onSwitch: (value: string) => void
  }
  
  type PricingCardProps = {
    isYearly?: boolean
    title: string
    monthlyPrice?: number
    yearlyPrice?: number
    description: string
    features: string[]
    popular?: boolean
    exclusive?: boolean
    price?:string
    disabled?:boolean
  }
  
  const PricingSwitch = ({ onSwitch }: PricingSwitchProps) => (
    <Tabs defaultValue="0" className="w-40 mx-auto" onValueChange={onSwitch}>
      <TabsList className="py-6 px-2">
        <TabsTrigger value="0" className="text-base">
          Monthly
        </TabsTrigger>
        <TabsTrigger value="1" className="text-base">
          Yearly
        </TabsTrigger>
      </TabsList>
    </Tabs>
  )
  

const PricingCard = ({ isYearly, title, monthlyPrice, yearlyPrice, description, features, popular, exclusive, disabled, price }: PricingCardProps) => {
    const { data: isLoggedIn, isFetching } = useQuery(
      ['isLoggedInHome'],
      async () => {
        const response = await supabaseUserClientComponentClient.auth.getUser();
        return Boolean(response.data.user?.id);
      },
      {
        initialData: false,
        refetchOnMount: true,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        cacheTime: 0,
        staleTime: 0,
      },
    );
    const href = isLoggedIn ? price === 'Free'?'disable':'/dashboard' : price === 'Free'?'/sign-up':'/login';
  return <Card
      className={cn(`max-w-96 w-full flex flex-col justify-between py-1 pb-4 ${popular ? "border-rose-400" : "border-zinc-700"} mx-auto sm:mx-0`, {
        "animate-background-shine bg-white dark:bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] transition-colors":
          exclusive,
      })}>
      <div>
        <CardHeader className="pb-8 pt-4">
          <div className="flex justify-between">
            <CardTitle className="text-zinc-700 dark:text-zinc-300 text-lg">{title}</CardTitle>
            {isYearly && yearlyPrice && monthlyPrice && (
              <div
                className={cn("px-2.5 rounded-xl h-fit text-sm py-1 bg-zinc-200 text-black dark:bg-zinc-800 dark:text-white", {
                  "bg-gradient-to-r from-orange-400 to-rose-400 dark:text-black ": popular,
                })}>
                Save ${monthlyPrice * 12 - yearlyPrice}
              </div>
            )}
          </div>
  
          {/* Display the price */}
          <div className="flex gap-0.5">
            <h3 className="text-3xl font-bold">
              {price === "Contact Us"
                ? "Contact Us"
                : monthlyPrice === undefined && yearlyPrice === undefined
                ? "Free"
                : yearlyPrice && isYearly
                ? "$" + yearlyPrice
                : "$" + monthlyPrice}
            </h3>
            <span className="flex flex-col justify-end text-sm mb-1">
              {price !== "Contact Us" && (yearlyPrice && isYearly ? "/year" : monthlyPrice ? "/month" : null)}
            </span>
          </div>
          <CardDescription className="pt-1.5 h-12">{description}</CardDescription>
        </CardHeader>
        <CardContent className="flex flex-col gap-2">
          {features.map((feature: string) => (
            <CheckItem key={feature} text={feature} />
          ))}
        </CardContent>
      </div>
       {href !== 'disable' && <CardFooter className="mt-2">
          <Link href={href} className="w-full">
            <Button
              className="relative inline-flex w-full items-center justify-center rounded-md bg-black text-white dark:bg-white px-6 font-medium dark:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
            >
              {href ==='/dashboard' && "Activate Plan"}
              {href === '/login' && "Try Now"}
              {href ==='/sign-up' && "Sign Up"}
            </Button>
          </Link>
        </CardFooter>
      }
    </Card>
  }

const MainPricing = ({ plans }) => {
  const [isYearly, setIsYearly] = useState(false);
  const togglePricingPeriod = (value: string) =>
    setIsYearly(parseInt(value) === 1);

  return (
    <>
      <PricingSwitch onSwitch={togglePricingPeriod} />
      <section className=" grid grid-cols-1 px-4 sm:px-6 md:container md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-8 overflow-x-auto">
        {plans.map((plan) => {
          return <PricingCard key={plan.title} {...plan} isYearly={isYearly} />;
        })}
      </section>
    </>
  );
};

export default MainPricing;
