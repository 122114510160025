'use client';
import { Button } from '@/components/ui/button';
import { Download } from 'lucide-react';
import { toast } from 'sonner';

const MackbookDownloadBtn = () => {
  const handleDownloadClick = () => {
    toast('The sample report will be available for download soon.', {
      position: 'top-center',
      duration: 3000,
    });
  };
  return (
    <div className="w-full md:w-fit" onClick={handleDownloadClick}>
      <Button variant="default" size="lg" className="group w-full md:w-fit">
        Download Sample Report
        <Download className="ml-2 -mr-1 w-5 h-5 group-hover:translate-x-1 transition" />
      </Button>
    </div>
  );
};

export default MackbookDownloadBtn;
